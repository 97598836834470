import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { fetchServices } from '../rtk/slices/services-slice'
import { fetchColor } from '../rtk/slices/color-slice'
import { fetchShow } from '../rtk/slices/show-slice'
import { fetchBlogs } from '../rtk/slices/blogs-slice'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import Navbar from './Navbar'
import Contact from './Contact'
import Footer from './Footer'

function All_Blogs () {
    window.scrollTo(0, 0); // العودة إلى الجزء العلوي

    const color = useSelector((state) => state.color.color);
    const dispatch = useDispatch();
    const services = useSelector((state) => state.blogs.blogs);
    const [servicesSearch, setServicesSearch] = useState(services);

    const { t, i18n } = useTranslation();
    i18next.on('languageChanged', function (lng) {
        // تحديث اتجاه الصفحة بناءً على اللغة الحالية
        document.documentElement.setAttribute('dir', lng === 'ar' ? 'rtl' : 'ltr');
    });

    const blogs_ar = services.map(blo_se => {
        if (i18n.language == "ar") {
            return ({
                "id": blo_se.id,
                "image": blo_se.image,
                "title": blo_se.title_ar,
                "description": blo_se.description_ar,
                "date": blo_se.date
            })
        }
        return blo_se;
    })

    const filterService = (searchKeyword) => {
        const updateData = services.filter((service) => service.title.includes(searchKeyword));
        setServicesSearch(updateData)
    }

    if (color.length > 0) {
        let inputString = color[0].color;
        let filteredValue = inputString.replace(/[^0-9,]/g, '');

        // تعيين قيمة المتغير --main-color في :root
        const root = document.documentElement;
        root.style.setProperty("--main-color", `${filteredValue}`);
    }

    useEffect(() => {
        if (sessionStorage.getItem("lang")) {
            i18n.changeLanguage(sessionStorage.getItem("lang"))
        }

        // fetch color
        dispatch(fetchColor());
        // fetch show
        dispatch(fetchShow());

        // fetch services
        dispatch(fetchBlogs());

    }, [])

    return (
        <>
            <Navbar />
            <section className='memories projects blogs pageOver' id='projects'>
                <div className="blur1"></div>
                <div className="blur2"></div>
                <div class="ball-1"></div>
                <div class="ball-2"></div>
                <div class="ball-3"></div>
                <div class="ball-4"></div>
                <div class="ball-6"></div>
                <div class="ball-5"></div>
                <div class="ball-7"></div>
                <div class="ball-8"></div>
                <div class="ball-9"></div>
                <div class="ball-10"></div>
                <div class="ball-11"></div>
                <div class="ball-12"></div>

                <div className="container">
                    <h2 className="title">
                        <span>{i18n.language == "ar" ? "جدول الأعمال" : "Agenda"}</span>
                        <p>{i18n.language == "ar" ? "جدول الأعمال" : "Agenda"}</p>
                    </h2>

                    {/* <form style={{ marginTop: "3rem" }}>
                    <div className="input-box">
                        <div className="input-field">
                            <input className='myInput' name='name' type="text" onChange={(e) => filterService(e.target.value.toLowerCase())} placeholder='Title: corona' required />
                            <div className="icon"><i class="fa fa-search"></i></div>
                        </div>
                    </div>
                    <div class="btn-box btns"><button class="button type1"><span class="btn-txt"><a>بحث</a></span></button></div>
                </form> */}

                    <div className="cards">

                        {
                            blogs_ar.map((card) => {
                                return (
                                    <Link to={`/agendas_page/agenda/${card.id}`} className="card" key={card.id}>
                                        <div style={{ textAlign: "center" }}>
                                            <img style={{ width: "100%", maxWidth: "19rem", borderRadius: ".5rem" }} src={`${process.env.REACT_APP_PROJECTS_API}assest/imgs/${card.image}`} alt="" />
                                        </div>
                                        <h4><span style={{ color: "var(--main-color-opacity-100)" }}>{i18n.language == "ar" ? "العنوان:- " : "Title:- "} </span>{card.title}</h4>
                                        <p>{card.description.substring(0, 100)}..</p>
                                    </Link>
                                )
                            })

                        }

                    </div>

                    <Link className='exit' to={`/`}>
                        <div class="menu active">
                            <span class="span1"></span>
                            <span class="span2"></span>
                            <span class="span3"></span>
                        </div>
                    </Link>
                </div>
            </section>

            <Contact />
            <Footer />
        </>
    )
}

export default All_Blogs
