import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import Navbar from './Navbar';
import i18next from 'i18next';

function Contact_Page () {
    i18next.on('languageChanged', function (lng) {
        // تحديث اتجاه الصفحة بناءً على اللغة الحالية
        document.documentElement.setAttribute('dir', lng === 'ar' ? 'rtl' : 'ltr');
    });

    const { t, i18n } = useTranslation();

    useEffect(() => {
        if (sessionStorage.getItem("lang")) {
            i18n.changeLanguage(sessionStorage.getItem("lang"))
        }


    }, [])
    return (
        <>
            <Navbar />
            <section className='contact pageOver cn' id='contact'>
                <div className="blur1"></div>
                <div className="blur2"></div>
                <div class="ball-1"></div>
                <div class="ball-2"></div>
                <div class="ball-3"></div>
                <div class="ball-4"></div>
                <div class="ball-6"></div>
                <div class="ball-5"></div>
                <div class="ball-7"></div>
                <div class="ball-8"></div>
                <div class="ball-9"></div>
                <div class="ball-10"></div>
                <div class="ball-11"></div>
                <div class="ball-12"></div>

                <div className="container">
                    <h2 className="title">
                        <span>{i18n.language == "ar" ? "إتصل بنا" : "Contact Us"}</span>
                        <p>{i18n.language == "ar" ? "إتصل بنا" : "Contact Us"}</p>
                    </h2>
                    <div className="contact-content">
                        <div className="contact-social">
                            <span>
                                <a href='https://www.facebook.com/laboratoy.biosafety?mibextid=ZbWKwL' target='_blank' class="btn-sosial">
                                    <span class="svgContainer">
                                    </span>
                                    <i class="fa fa-facebook-f"></i>
                                    <i class="fa fa-facebook-f hover"></i>
                                    <span class="BG-social"></span>
                                </a>
                            </span>
                            <span>
                                <a href='mailto:laboratorybiosafety@gmail.com' target='_blank' class="btn-sosial">
                                    <span class="svgContainer">
                                    </span>
                                    <i class="fa fa-envelope"></i>
                                    <i class="fa fa-envelope hover"></i>
                                    <span class="BG-social"></span>
                                </a>
                            </span>

                            <span>
                                <a href='https://www.instagram.com/laboratory_biosafety?igsh=MWJnZnJpZmRibHJvOA==' target='_blank' class="btn-sosial">
                                    <span class="svgContainer">
                                    </span>
                                    <i class="fa fa-instagram"></i>
                                    <i class="fa fa-instagram hover"></i>
                                    <span class="BG-social"></span>
                                </a>
                            </span>

                            <span>
                                <a href='https://x.com/Lab_Biosafety?t=PBWShs2gh3jyfh7m2tMcbw&s=09' target='_blank' class="btn-sosial">
                                    <span class="svgContainer">
                                    </span>
                                    <i class="fa fa-twitter"></i>
                                    <i class="fa fa-twitter hover"></i>
                                    <span class="BG-social"></span>
                                </a>
                            </span>
                        </div>
                    </div>
                    <div class="map">
                        <div className="des">
                            <div style={{ color: "gray", fontSize: "1.4rem", paddingBottom: "1rem" }}>{i18n.language == "ar" ? "ساعات العمل: 24 ساعة" : "Working hours: 24 hours"}</div>
                            <label className='labelContact' htmlFor="address">{i18n.language == "ar" ? "العنوان" : "Address Info"}</label>
                            <div className="address">{i18n.language == "ar" ? "المنوفية ، بركة السبع ، مختبر مستشفى شنتينا الحجر للحمى" : "Menoufia, Berket El Sab, Shentena Elhager fever hospital laboratory"}</div>
                            <label className='labelContact' htmlFor="address">{i18n.language == "ar" ? "البريد الإلكتروني" : "Email"}</label>
                            <div className="email"><a href="mailto:laboratorybiosafety@gmail.com?subject=Website">laboratorybiosafety@gmail.com</a></div>
                        </div>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d27462.45193427869!2d31.0567605!3d30.639410700000003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14f7d06a9c99cbe7%3A0xb89c3b04f3ba0a14!2z2LTZhtiq2YbYpyDYp9mE2K3YrNixINmI2K3Ytdiq2YfYp9iMINmF2LHZg9iyINio2LHZg9ipINin2YTYs9io2LnYjCDZhdit2KfZgdi42Kkg2KfZhNmF2YbZiNmB2YrYqQ!5e0!3m2!1sar!2seg!4v1708823429660!5m2!1sar!2seg"></iframe>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Contact_Page
