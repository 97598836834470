import React, { useEffect, useState } from 'react'
import logos from "../assest/logo.png"
import { useDispatch, useSelector } from 'react-redux';
import { fetchLogo } from '../rtk/slices/logo-slice';
import i18next from 'i18next';

function Footer () {
    i18next.on('languageChanged', function (lng) {
        // تحديث اتجاه الصفحة بناءً على اللغة الحالية
        document.documentElement.setAttribute('dir', lng === 'ar' ? 'rtl' : 'ltr');
    });

    const dispatch = useDispatch();
    const logo = useSelector((state) => state.logo.logo);

    useEffect(() => {
        // fetch logo
        dispatch(fetchLogo())
    }, [])
    return (
        <footer className='footer'>
            <div className="container">
                <div className="footer-top">
                    <img className='logo' src={logo.length > 0 ? `${process.env.REACT_APP_PROJECTS_API}assest/imgs/${logo[0].logo}` : logos} alt="" />
                </div>
                <div className="footer-bottom">
                    <p>©Copyright 2024 <a href="#">Laboratory Biosafety</a></p>
                </div>
            </div>
        </footer>
    )
}

export default Footer
