import React, { useEffect, useState } from 'react'
import landing from '../assest/landing.png'
import Aos from 'aos';
import "aos/dist/aos.css"
import { useTranslation } from 'react-i18next';

function Home () {
    window.scrollTo(0, 0); // العودة إلى الجزء العلوي

    const [home, setHome] = useState([]);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        if (sessionStorage.getItem("lang")) {
            i18n.changeLanguage(sessionStorage.getItem("lang"))
        }

        Aos.init();

        // fetch home
        const fetchLogo = async () => {
            const res = await fetch(`${process.env.REACT_APP_PROJECTS_API}components/s_d.php?logo`)
            const data = await res.json()
            return setHome(data);
        }
        fetchLogo()
    }, [])
    return (
        <section className='home' id='home'>
            <div className="blur1"></div>
            <div className="blur2"></div>
            <div class="ball-1"></div>
            <div class="ball-2"></div>
            <div class="ball-3"></div>
            <div class="ball-4"></div>
            <div class="ball-6"></div>
            <div class="ball-5"></div>
            <div class="ball-7"></div>
            <div class="ball-8"></div>
            <div class="ball-9"></div>
            <div class="ball-10"></div>
            <div class="ball-11"></div>
            <div class="ball-12"></div>

            <div className="container bg-container">
                <div className="social">
                    <ul>
                        <li><a target='_blank' href="https://www.facebook.com/laboratoy.biosafety?mibextid=ZbWKwL"><i className="fa fa-facebook-f"></i></a></li>
                        <li><a target='_blank' href="https://www.instagram.com/laboratory_biosafety?igsh=MWJnZnJpZmRibHJvOA=="><i className="fa fa-instagram"></i></a></li>
                    </ul>
                </div>
                <div className="content">
                    <div data-aos="fade-left" data-aos-duration="3000" className="text-area">
                        <div className="text">
                            <h1> <span>{i18n.language == "ar" ? "مختبر السلامة الأحيائية التعلم الإلكتروني" : "Laboratory Biosafety e-Learning"}</span> <br /><h4 className='title2'>{i18n.language == "ar" ? "حافظ على سلامتك" : "Keep Safe"}</h4></h1>
                            <p>{i18n.language == "ar" ? "لقد تم تطوير دليل السلامة الحيوية هذا لتوفير السياسات والإجراءات العامة عند التعامل مع المخاطر البيولوجية في معاملنا. يجب على كل مختبر أن يحتفظ بهذا الدليل الخاص بسياسات المختبر والإجراءات والتدريبات التي من شأنها تقليل المخاطر المحددة الموجودة في المختبر. تعتمد السلامة الحيوية في المختبرات على احتواء المخاطر البيولوجية لمنع تعرض العاملين في المختبر وكذلك البيئة الخارجية لها." : "This Biosafety Manual has been developed to provide general policies and procedures when dealing with biological hazards in our laboratories. Each laboratory must supplement this manual with laboratory-specific policies; Procedures and training that will reduce the specific risks present in the laboratory. Laboratory biosafety is based on the containment of biological hazards to prevent exposure to laboratory workers and the outside environment."}</p>
                            <div className="btns">
                                <button class="button type1">
                                    <span class="btn-txt"><a href="#courses">{i18n.language == "ar" ? "كل الدورات" : "All Courses"}</a></span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div data-aos="fade-right" data-aos-duration="3000" className="image-area">
                        <div className="image">
                            <img src={landing} alt="" />
                        </div>
                    </div>
                </div>

                <div className="reviews">
                    <div class="counter">
                        <ul>
                            <li>
                                <div class="single-counter">
                                    <div class="content-review">
                                        <div class="number">
                                            <span className='icon'><svg xmlns="http://www.w3.org/2000/svg" height={"1.7rem"} viewBox="0 0 640 512"><path d="M175 389.4c-9.8 16-15 34.3-15 53.1c-10 3.5-20.8 5.5-32 5.5c-53 0-96-43-96-96V64C14.3 64 0 49.7 0 32S14.3 0 32 0H96h64 64c17.7 0 32 14.3 32 32s-14.3 32-32 32V309.9l-49 79.6zM96 64v96h64V64H96zM352 0H480h32c17.7 0 32 14.3 32 32s-14.3 32-32 32V214.9L629.7 406.2c6.7 10.9 10.3 23.5 10.3 36.4c0 38.3-31.1 69.4-69.4 69.4H261.4c-38.3 0-69.4-31.1-69.4-69.4c0-12.8 3.6-25.4 10.3-36.4L320 214.9V64c-17.7 0-32-14.3-32-32s14.3-32 32-32h32zm32 64V224c0 5.9-1.6 11.7-4.7 16.8L330.5 320h171l-48.8-79.2c-3.1-5-4.7-10.8-4.7-16.8V64H384z" /></svg></span>
                                        </div>
                                        <p>{i18n.language == "ar" ? "خبراء" : "Experts"}</p>
                                    </div>
                                </div>
                            </li>
                            <li >
                                <div class="single-counter">
                                    <div class="content-review">
                                        <div class="number">
                                            <span className='icon'><svg xmlns="http://www.w3.org/2000/svg" height={"2rem"} viewBox="0 0 512 512"><path d="M269.4 2.9C265.2 1 260.7 0 256 0s-9.2 1-13.4 2.9L54.3 82.8c-22 9.3-38.4 31-38.3 57.2c.5 99.2 41.3 280.7 213.6 363.2c16.7 8 36.1 8 52.8 0C454.7 420.7 495.5 239.2 496 140c.1-26.2-16.3-47.9-38.3-57.2L269.4 2.9zM144 221.3c0-33.8 27.4-61.3 61.3-61.3c16.2 0 31.8 6.5 43.3 17.9l7.4 7.4 7.4-7.4c11.5-11.5 27.1-17.9 43.3-17.9c33.8 0 61.3 27.4 61.3 61.3c0 16.2-6.5 31.8-17.9 43.3l-82.7 82.7c-6.2 6.2-16.4 6.2-22.6 0l-82.7-82.7c-11.5-11.5-17.9-27.1-17.9-43.3z" /></svg></span>
                                        </div>
                                        <p>{i18n.language == "ar" ? "سلامة" : "Keep Safe"}</p>
                                    </div>
                                </div>
                            </li>
                            <li >
                                <div class="single-counter">
                                    <div class="content-review">
                                        <div class="number">
                                            <span className='icon'><svg xmlns="http://www.w3.org/2000/svg" height={"2rem"} viewBox="0 0 384 512"><path d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM80 64h64c8.8 0 16 7.2 16 16s-7.2 16-16 16H80c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64h64c8.8 0 16 7.2 16 16s-7.2 16-16 16H80c-8.8 0-16-7.2-16-16s7.2-16 16-16zm54.2 253.8c-6.1 20.3-24.8 34.2-46 34.2H80c-8.8 0-16-7.2-16-16s7.2-16 16-16h8.2c7.1 0 13.3-4.6 15.3-11.4l14.9-49.5c3.4-11.3 13.8-19.1 25.6-19.1s22.2 7.7 25.6 19.1l11.6 38.6c7.4-6.2 16.8-9.7 26.8-9.7c15.9 0 30.4 9 37.5 23.2l4.4 8.8H304c8.8 0 16 7.2 16 16s-7.2 16-16 16H240c-6.1 0-11.6-3.4-14.3-8.8l-8.8-17.7c-1.7-3.4-5.1-5.5-8.8-5.5s-7.2 2.1-8.8 5.5l-8.8 17.7c-2.9 5.9-9.2 9.4-15.7 8.8s-12.1-5.1-13.9-11.3L144 349l-9.8 32.8z" /></svg></span>
                                        </div>
                                        <p>{i18n.language == "ar" ? "دورات" : "Courses"}</p>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section >
    )
}

export default Home
