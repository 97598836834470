import React from 'react'
import Navbar from './Navbar'
import Home from './Home'
import About from './About'
import Certificates from './Certificates'
import Memories from './Memories'
import Footer from './Footer'
import Loader from './Loader'
import Projects from './Projects'
import Contact from './Contact'
import Blogs from './Blogs'
import Achievements from './Achievements'
import Gallery from './Projects'

function All () {
    return (
        <>
            <Loader />
            <Navbar />
            <Home />
            <About />
            <Achievements />
            <Certificates />
            {/* <Projects /> */}
            <Blogs />
            {/* <Memories /> */}
            {/* <Gallery /> */}
            <Contact />
            <Footer />
        </>
    )
}

export default All
