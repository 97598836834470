import React, { useRef, useState, useEffect } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';


// import required modules
import { Autoplay } from 'swiper/modules';

import swiper1 from '../assest/about1.png'
import swiper3 from '../assest/about3.png'
import swiper4 from '../assest/about4.png'
import swiper5 from '../assest/about5.png'
import swiper6 from '../assest/about6.png'
import swiper7 from '../assest/about7.png'
import AboutImage from '../assest/aboutimage.jpg'
import { useDispatch, useSelector } from 'react-redux';
import { fetchPartners } from '../rtk/slices/partners-slice';
import { fetchLogo } from '../rtk/slices/logo-slice';
import { fetchAbout } from '../rtk/slices/about-slice';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import CountUp from 'react-countup';
import ScrollTrigger from 'react-scroll-trigger';

function About () {
    // أضف المنطق لتحديد عدد العناصر المرئية بناءً على عرض الشاشة
    const slidesPerView = window.innerWidth >= 768 ? 4 : 2;

    const [counterState, SetCounterState] = useState(false);
    const dispatch = useDispatch();
    const about = useSelector((state) => state.about.about);
    const partners = useSelector((state) => state.partners.partners);

    const { t, i18n } = useTranslation();

    const about_ar = about.map(ab_ar => {
        if (i18n.language == "ar") {
            return ({
                "id": ab_ar.id,
                "img": ab_ar.img,
                "text": ab_ar.text_ar
            })
        }
        return ab_ar;
    })

    useEffect(() => {
        if (sessionStorage.getItem("lang")) {
            i18n.changeLanguage(sessionStorage.getItem("lang"))
        }

        // fetch about
        dispatch(fetchAbout());
        dispatch(fetchPartners());
    }, [])
    return (
        <>
            <section className='about' id='about'>
                <div className="blur1"></div>
                <div className="blur2"></div>
                <div class="ball-1"></div>
                <div class="ball-2"></div>
                <div class="ball-3"></div>
                <div class="ball-4"></div>
                <div class="ball-6"></div>
                <div class="ball-5"></div>
                <div class="ball-7"></div>
                <div class="ball-8"></div>
                <div class="ball-9"></div>
                <div class="ball-10"></div>
                <div class="ball-11"></div>
                <div class="ball-12"></div>

                <div className="container">
                    <h2 data-aos="fade-left" className="title">
                        <span>{i18n.language == "ar" ? "من نحن" : "About Us"}</span>
                        <p>{i18n.language == "ar" ? "من نحن" : "About Us"}</p>
                    </h2>

                    <div className="about-content">
                        {/* {about.map((content) => {
                        return (
                            <div key={content.id} className="box">
                                <img data-aos="fade-up"
                                    data-aos-anchor-placement="top-bottom" className='image' src={`${process.env.REACT_APP_PROJECTS_API}assest/imgs/${content.img}`} alt="" />

                                <div data-aos="zoom-in" data-aos-duration="1500" className="text">
                                    {content.text}
                                </div>
                            </div>
                        )
                    })} */}

                        <div className="box">
                            {about_ar.map((website) => {
                                return (
                                    <>
                                        <div data-aos="zoom-in" data-aos-duration="1500" className="text">
                                            <p className='priceP'>
                                                <span style={{ color: "#000" }} className='priceSpan'>{i18n.language == "ar" ?
                                                    (<>يتكون فريقنا من محترفين تم اختيارهم لخبرتهم في مجالات تخصصهم فإنهم ليسوا مجرد أخصائي مختبرات بل يتفوقون في مجالاتهم ويعملون بفعالية. <h5>د. نهى إبراهيم</h5> حاصلة على درجة الدكتوراه في التشخيص الجزيئي للأمراض البشرية ومتخصصة في السلامة البيولوجية والسلامة والصحة المهنية وإدارة المخاطر.</>)
                                                    : (<>Our team consists of professionals chosen for their expertise in their respective domains. They are not just laboratory technologists; they excel in their fields and work effectively.<h5> Dr. Noha L. Ibrahim</h5> holds a Ph.D. in Molecular Diagnostics of Human Diseases and specializes in biological safety, occupational safety and health, and risk management.</>)}</span><br />
                                            </p>
                                        </div>
                                        <img data-aos="fade-up"
                                            data-aos-anchor-placement="top-bottom" className='image' src={`${process.env.REACT_APP_PROJECTS_API}assest/imgs/${website.img}`} alt="" />

                                        <div className="counter" style={{ paddingTop: "2rem" }}>
                                            {/* <p className="titleAchivmenents">
                                                {i18n.language == "ar" ? "لماذا نحن فخورون؟" : "Why We Are Proud?"}
                                            </p> */}
                                            {/* <ScrollTrigger onEnter={() => SetCounterState(true)} onExit={() => SetCounterState(false)}>

                                                <div className="cards">
                                                    <div className="card">
                                                        <h2>
                                                            {
                                                                counterState &&
                                                                <CountUp start={0} end={1}>
                                                                    duration={6}

                                                                </CountUp>
                                                            }
                                                        </h2>
                                                        <h4>{i18n.language == "ar" ? " المختبر الإقليمي" : "co regional laboratory"}</h4>
                                                    </div>
                                                    <div className="card">
                                                        <h2>
                                                            {
                                                                counterState &&
                                                                <CountUp start={0} end={20}>
                                                                    duration={5}

                                                                </CountUp>
                                                            }
                                                        </h2>
                                                        <h4>{i18n.language == "ar" ? "المستشفيات" : "hospitals"}</h4>
                                                    </div>
                                                    <div className="card">
                                                        <h2>
                                                            {
                                                                counterState &&
                                                                <CountUp start={0} end={10}>
                                                                    duration={6}

                                                                </CountUp>
                                                            }
                                                        </h2>
                                                        <h4>{i18n.language == "ar" ? "الإدارات الصحية" : "Health Departments"}</h4>
                                                    </div>
                                                    <div className="card">
                                                        <h2>
                                                            {
                                                                counterState &&
                                                                <CountUp start={0} end={400}>
                                                                    duration={3.5}

                                                                </CountUp>
                                                            }
                                                        </h2>
                                                        <h4>{i18n.language == "ar" ? "الوحدات الصحية" : "Health Units"}</h4>
                                                    </div>
                                                </div>
                                            </ScrollTrigger> */}

                                            <Link class="btn two" to="/about_page"><svg width="60" height="60" viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg"><path d="M1.51038 32.6729C-0.990382 34.1837 -0.232333 37.9945 2.65612 38.4335C4.97848 38.7866 6.09439 41.4804 4.70164 43.3722C2.96938 45.7248 5.12803 48.9555 7.9646 48.2555C10.245 47.6925 12.3074 49.7548 11.7445 52.0354C11.0445 54.8721 14.2752 57.0306 16.6278 55.2984C18.5196 53.9057 21.2139 55.0216 21.5665 57.3439C22.0054 60.2322 25.8161 60.9904 27.3271 58.4896C28.5419 56.479 31.4581 56.479 32.6729 58.4896C34.1837 60.9904 37.9945 60.2323 38.4335 57.3439C38.7866 55.0215 41.4804 53.9056 43.3722 55.2984C45.7248 57.0306 48.9555 54.872 48.2555 52.0354C47.6925 49.755 49.7548 47.6925 52.0354 48.2555C54.872 48.9555 57.0306 45.7248 55.2984 43.3722C53.9057 41.4804 55.0216 38.7861 57.3439 38.4335C60.2322 37.9946 60.9904 34.1839 58.4896 32.6729C56.479 31.4581 56.479 28.5419 58.4896 27.3271C60.9904 25.8163 60.2323 22.0055 57.3439 21.5665C55.0215 21.2134 53.9056 18.5196 55.2984 16.6278C57.0306 14.2752 54.872 11.0445 52.0354 11.7445C49.7549 12.3075 47.6925 10.2452 48.2555 7.9646C48.9555 5.12795 45.7248 2.96938 43.3722 4.70164C41.4804 6.09426 38.7861 4.9784 38.4335 2.65612C37.9946 -0.23224 34.1839 -0.990422 32.6729 1.51038C31.4581 3.52095 28.5419 3.52095 27.3271 1.51038C25.8163 -0.990382 22.0055 -0.232333 21.5665 2.65612C21.2134 4.97848 18.5196 6.09439 16.6278 4.70164C14.2752 2.96938 11.0445 5.12803 11.7445 7.9646C12.3075 10.245 10.2452 12.3074 7.9646 11.7445C5.12795 11.0445 2.96938 14.2752 4.70164 16.6278C6.09426 18.5196 4.9784 21.2139 2.65612 21.5665C-0.232239 22.0054 -0.990423 25.8161 1.51038 27.3271C3.52095 28.5419 3.52095 31.4581 1.51038 32.6729Z"></path></svg><div class="btn-content">{i18n.language == "ar" ? "قراءة المزيد" : "Read More"}<span><i class="fa fa-arrow-up"></i></span></div></Link>
                                        </div>
                                    </>
                                )
                            })}
                        </div>


                    </div>
                </div>
            </section>
        </>

    )
}

export default About
