import React, { useRef, useState, useEffect } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAchievements } from '../rtk/slices/achievements-slice';
import { useTranslation } from 'react-i18next';

import pageabout from '../assest/pageabout.jpg'
import { fetchAbout } from '../rtk/slices/about-slice';
import i18next from 'i18next';
import Navbar from './Navbar';
import Contact from './Contact';
import Footer from './Footer';
import { fetchLogo } from '../rtk/slices/logo-slice';

function Page_About () {

    window.scrollTo(0, 0); // العودة إلى الجزء العلوي

    // أضف المنطق لتحديد عدد العناصر المرئية بناءً على عرض الشاشة
    const dispatch = useDispatch();
    const achievements = useSelector((state) => state.achievements.achievements);

    const { t, i18n } = useTranslation();

    i18next.on('languageChanged', function (lng) {
        // تحديث اتجاه الصفحة بناءً على اللغة الحالية
        document.documentElement.setAttribute('dir', lng === 'ar' ? 'rtl' : 'ltr');
    });
    useEffect(() => {
        if (sessionStorage.getItem("lang")) {
            i18n.changeLanguage(sessionStorage.getItem("lang"))
        }

        // fetch about
        dispatch(fetchAbout());
    }, [])
    return (
        <>
            <Navbar />
            <section className='about pageOver'>
                <div className="blur1"></div>
                <div className="blur2"></div>
                <div class="ball-1"></div>
                <div class="ball-2"></div>
                <div class="ball-3"></div>
                <div class="ball-4"></div>
                <div class="ball-6"></div>
                <div class="ball-5"></div>
                <div class="ball-7"></div>
                <div class="ball-8"></div>
                <div class="ball-9"></div>
                <div class="ball-10"></div>
                <div class="ball-11"></div>
                <div class="ball-12"></div>
                <div className="container">
                    <h2 className="title">
                        <span>{i18n.language == "ar" ? "من نحن" : "About Us"}</span>
                        <p>{i18n.language == "ar" ? "من نحن" : "About Us"}</p>
                    </h2>

                    <div className="about-content">

                        <div className="text">
                            <div className="box-q" style={{ padding: "3rem 0" }}>
                                <p className='titleAchivmenents'>
                                    {i18n.language == "ar" ? "من هي الدكتورة نهى إبراهيم؟" : "Who is Dr Noha L. Ibrahim?"}
                                </p>

                                <p className='priceP'>
                                    <span className='priceSpan pg' style={{ color: "#000" }}>
                                        {i18n.language == "ar" ?
                                            (<>
                                                <p>• أنشأت نظامًا للسلامة الحيوية في مختبرها بالمستشفى</p>
                                                <br />
                                                <p>• أسست ثقافة السلامة الحيوية وتدريب العاملين في المختبرات</p>
                                                <br />
                                                <p>• قامت بتطبيق معايير السلامة المخبرية في المستشفى للحفاظ على سلامتهم وسلامة المرضى والمجتمع</p>
                                                <br />
                                                <p>• حاصلة على درجة البكالوريوس في العلوم من قسم الكيمياء عام 1997</p>
                                                <br />
                                                <p>• حاصلة على دبلوم في الأحياء الدقيقة عام 1999</p>
                                                <br />
                                                <p>• حاصلة على دبلوم الكيمياء الحيوية السريرية عام 2003</p>
                                                <br />
                                                <p>• حاصلة على درجة الماجستير في التشخيص الجزيئي للأمراض البشرية عام 2013</p>
                                                <br />
                                                <p>• حاصلة على درجة الدكتوراه في التشخيص الجزيئي للأمراض البشرية 2018</p>
                                                <br />
                                                <p>• تدربت على إدارة السلامة الحيوية الأساسية والمتقدمة 2022_2023</p>
                                                <br />
                                                <p>• تدربت على السلامة والصحة المهنية الأساسية والمتقدمة في عام 2024</p>
                                            </>)
                                            :
                                            (<>
                                                <p>• She established a biosafety system in her hospital laboratory</p>
                                                <br />
                                                <p>• She establishment of a biosafety culture and training of the laboratory workers</p>
                                                <br />
                                                <p>• She applied laboratory safety standards in the hospital to maintain their safety and the safety of patients and the community</p>
                                                <br />
                                                <p>• She holds a Bachelor's Degree in Science, from the Department of Chemistry, 1997</p>
                                                <br />
                                                <p>• She holds a diploma in microbiology in 1999</p>
                                                <br />
                                                <p>• She holds a diploma in clinical biochemistry in 2003</p>
                                                <br />
                                                <p>• She holds a Master’s degree in molecular diagnosis of human diseases in 2013</p>
                                                <br />
                                                <p>• She holds a PhD in Molecular Diagnosis of Human Diseases 2018</p>
                                                <br />
                                                <p>• She is trained in basic and advanced biosafety management 2022_2023</p>
                                                <br />
                                                <p>• She is trained in basic and advanced safety and occupational health in 2024</p>
                                            </>)
                                        }
                                    </span><br />
                                </p>
                            </div>
                            <div className="imageab">
                                <img width={"100%"} src={pageabout} alt="" />
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <Contact />
            <Footer />
        </>
    )
}

export default Page_About
