import React, { useEffect, useState } from 'react'
import { Link, useParams, useHistory, useNavigate } from 'react-router-dom'
import service from '../assest/des1.jpg'
import { useDispatch, useSelector } from 'react-redux';
import { fetchColor } from '../rtk/slices/color-slice';
import { fetchServices } from '../rtk/slices/services-slice';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

function Service () {
    i18next.on('languageChanged', function (lng) {
        // تحديث اتجاه الصفحة بناءً على اللغة الحالية
        document.documentElement.setAttribute('dir', lng === 'ar' ? 'rtl' : 'ltr');
    });

    window.scrollTo(0, 0); // العودة إلى الجزء العلوي
    const { id } = useParams();

    const navigate = useNavigate();
    // توجيه المستخدم إلى القسم "Contact"
    const handleButtonClick = () => {
        navigate('/#contact');
    };


    const dispatch = useDispatch();
    const services = useSelector((state) => state.services.services);
    const color = useSelector((state) => state.color.color);
    const { t, i18n } = useTranslation();

    const services_ar = services.map(se_ar => {
        if (i18n.language == "ar") {
            return ({
                "id": se_ar.id,
                "keyword": se_ar.keyword_ar,
                "img_service": se_ar.img_service,
                "img_service2": se_ar.img_service2,
                "whats_service": se_ar.whats_service_ar,
                "url_video": se_ar.url_video,
                "url_video2": se_ar.url_video2,
                "url_attachment": se_ar.url_attachment,
            })
        }
        return se_ar;
    })

    if (color.length > 0) {
        let inputString = color[0].color;
        let filteredValue = inputString.replace(/[^0-9,]/g, '');

        // تعيين قيمة المتغير --main-color في :root
        const root = document.documentElement;
        root.style.setProperty("--main-color", `${filteredValue}`);
    }


    useEffect(() => {
        if (sessionStorage.getItem("lang")) {
            i18n.changeLanguage(sessionStorage.getItem("lang"))
        }


        // fetch color
        dispatch(fetchColor());

        // fetch services
        dispatch(fetchServices());

    }, [])

    return (
        <section className='projects service pageOver' id='projects'>
            <div className="blur1"></div>
            <div className="blur2"></div>
            <div class="ball-1"></div>
            <div class="ball-2"></div>
            <div class="ball-3"></div>
            <div class="ball-4"></div>
            <div class="ball-6"></div>
            <div class="ball-5"></div>
            <div class="ball-7"></div>
            <div class="ball-8"></div>
            <div class="ball-9"></div>
            <div class="ball-10"></div>
            <div class="ball-11"></div>
            <div class="ball-12"></div>

            <div className="container">
                <h2 className="title">
                    <span>{i18n.language == "ar" ? "الدورات" : "Courses"}</span>
                    <p style={{ textTransform: "capitalize" }}>{i18n.language == "ar" ? "الدورات" : "Courses"}</p>
                </h2>

                {services_ar.map((service) => {
                    return (
                        service['id'] == id ?
                            <div className="card-service" style={{ color: "#000" }}>
                                <div className="img">
                                    <img src={`${process.env.REACT_APP_PROJECTS_API}assest/imgs/${service.img_service}`} alt="" />
                                    {service.img_service2.length > 6 &&
                                        <>
                                            <img src={`${process.env.REACT_APP_PROJECTS_API}assest/imgs/${service.img_service2}`} alt="" />

                                        </>}
                                </div>
                                <div className="content">
                                    <div className="box">
                                        <h3 style={{ paddingBottom: "2rem" }}>{service.keyword}</h3>
                                        <p>
                                            {service.whats_service.split(',').map((what) => {
                                                const [question, answer] = what.split('&');
                                                return (
                                                    <>
                                                        <span style={{ color: "black", fontWeight: "700" }}>{question}</span>
                                                        <br />
                                                        <span style={{ color: "#616161", paddingTop: "1rem" }}>{answer}</span><br />
                                                    </>
                                                )
                                            })}
                                        </p>
                                        <br />
                                        {service.url_video.length > 5 &&
                                            <>
                                                <div className="video" style={{ textAlign: "center" }}>
                                                    <button class="button type1"><span class="btn-txt"><a target='_blank' className='adownload' href={service.url_video} >{i18n.language == "ar" ? "مشاهدة الفيديوا" : "Watch a video"}</a></span></button>
                                                </div>
                                                <br />
                                            </>
                                        }
                                        {service.url_video2.length > 5 &&
                                            <>
                                                <div className="video" style={{ textAlign: "center" }}>
                                                    <button class="button type1"><span class="btn-txt"><a target='_blank' className='adownload' href={service.url_video2} >{i18n.language == "ar" ? "مشاهدة الفيديوا 2" : "Watch a video 2"}</a></span></button>
                                                </div>
                                                <br />
                                            </>
                                        }
                                        {service.url_attachment.length > 5 &&
                                            <>
                                                <div className="downloadd" style={{ textAlign: "center" }}>
                                                    <button class="button type1"><span class="btn-txt"><a target='_blank' className='adownload' href={service.url_attachment} >{i18n.language == "ar" ? "تحميل المستند" : "Download Pdf"}</a></span></button>
                                                </div>
                                            </>
                                        }

                                    </div>
                                    {/* <div className="box">
                                        <h3>المميزات</h3>
                                        <p>
                                            {service.features_service.split(',').map((feature) => {
                                                return (
                                                    <>
                                                        <span>{feature}</span><br />
                                                    </>
                                                )
                                            })}
                                        </p>
                                    </div>
                                    <div className="box">
                                        <h3>الاسعار</h3>
                                        <p className='priceP'>
                                            {service.prices_service.split(',').map((price) => {
                                                return (
                                                    <>
                                                        <span className='priceSpan'>{price}</span><br />
                                                    </>
                                                )
                                            })}
                                        </p>
                                    </div> */}
                                    {/* <div style={{ textAlign: "center" }} class="btns"><button class="button type1"><span class="btn-txt"><Link to={`/contact`}>اطلب الان</Link></span></button></div> */}
                                </div>
                            </div>
                            : null
                    )
                })}


                <Link className='exit' to={`/courses_page`}>
                    <div class="menu active">
                        <span class="span1"></span>
                        <span class="span2"></span>
                        <span class="span3"></span>
                    </div>
                </Link>
            </div>
        </section>
    )
}

export default Service
