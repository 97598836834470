import React, { useRef, useState, useEffect } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAchievements } from '../rtk/slices/achievements-slice';
import { useTranslation } from 'react-i18next';

import gornal from '../assest/gornal.jpg'
import { Link } from 'react-router-dom';
import Navbar from './Navbar';
import i18next from 'i18next';
import Contact from './Contact';
import Footer from './Footer';

function Achievements_Page () {
    window.scrollTo(0, 0); // العودة إلى الجزء العلوي
    i18next.on('languageChanged', function (lng) {
        // تحديث اتجاه الصفحة بناءً على اللغة الحالية
        document.documentElement.setAttribute('dir', lng === 'ar' ? 'rtl' : 'ltr');
    });

    // أضف المنطق لتحديد عدد العناصر المرئية بناءً على عرض الشاشة
    const dispatch = useDispatch();
    const achievements = useSelector((state) => state.achievements.achievements);
    const { t, i18n } = useTranslation();

    const achievements_ar = achievements.map(ac_ar => {
        if (i18n.language == "ar") {
            return ({
                "id": ac_ar.id,
                "title": ac_ar.title_ar,
                "description": ac_ar.description_ar,
                "img1": ac_ar.img1,
                "img2": ac_ar.img2,
                "img3": ac_ar.img3,
                "img4": ac_ar.img4,
            })
        }
        return ac_ar;
    })


    useEffect(() => {
        if (sessionStorage.getItem("lang")) {
            i18n.changeLanguage(sessionStorage.getItem("lang"))
        }

        // fetch about
        dispatch(fetchAchievements());
    }, [])
    return (
        <>
            <Navbar />

            <section className='about achievements pageOver' id='achievements'>
                <div className="blur1"></div>
                <div className="blur2"></div>
                <div class="ball-1"></div>
                <div class="ball-2"></div>
                <div class="ball-3"></div>
                <div class="ball-4"></div>
                <div class="ball-6"></div>
                <div class="ball-5"></div>
                <div class="ball-7"></div>
                <div class="ball-8"></div>
                <div class="ball-9"></div>
                <div class="ball-10"></div>
                <div class="ball-11"></div>
                <div class="ball-12"></div>
                <div className="container">
                    <h2 className="title">
                        <span>{i18n.language == "ar" ? "الإنجازات" : "Achievements"}</span>
                        <p>{i18n.language == "ar" ? "الإنجازات" : "Achievements"}</p>
                    </h2>

                    <div className="about-content">
                        {/* {about.map((content) => {
                        return (
                            <div key={content.id} className="box">
                                <img data-aos="fade-up"
                                    data-aos-anchor-placement="top-bottom" className='image' src={`${process.env.REACT_APP_PROJECTS_API}assest/imgs/${content.img}`} alt="" />

                                <div data-aos="zoom-in" data-aos-duration="1500" className="text">
                                    {content.text}
                                </div>
                            </div>
                        )
                    })} */}

                        <div className="box">
                            {achievements_ar.map((website) => {
                                return (
                                    <>
                                        <div className="text">
                                            <div className="box-q">
                                                <p className='titleAchivmenents'>
                                                    {i18n.language == "ar" ? "من هي الدكتورة عبير عبد العزيز رائدة السلامة الحيوية في مصر وأفريقيا والشرق الأوسط؟" : "Who is Dr. Abeer Abd El-Aziz the pioneer of biosafety in Egypt Africa and Middle East?"}
                                                </p>

                                                <p className='priceP'>
                                                    <span className='priceSpan pg' style={{ color: "#000" }}>
                                                        {i18n.language == "ar" ?
                                                            (<>
                                                                <p>* وُلدت في الشرقية في عام 1975 وتعمل حاليًا كمدير فني لقسم علم الأحياء الدقيقة في الغذاء ومسؤولة السلامة الحيوية والمخاطر في المعامل المركزية بالقاهرة. بالإضافة إلى ذلك، فهي مستشارة المخاطر الحيوية بشمال افريقيا والشرق الاوسط.</p>
                                                                <br />
                                                                <p>* تقديرًا لمساهماتها المتميزة في مجال السلامة الحيوية، تم تكريمها بجائزة بطل السلامة الحيوية العالمية من قبل الاتحاد الدولي لجمعيات السلامة الحيوية في عام 2019، مما جعلها تكتب التاريخ كأول امرأة مصرية وعربية وأفريقية تتلقى هذا التقدير المرموق.</p>
                                                                <br />
                                                                <p>* منذ عام 2022 كانت جزءًا أساسيًا من لجنة اختيار جائزة بطل السلامة الحيوية العالمية ومشاركة نشطة في تحديد وتكريم الأفراد الذين قاموا بخطوات كبيرة في مجال السلامة الحيوية.</p>
                                                                <br />
                                                                <p>* حاصلة على لقب مدير السلامة الحيوية المعتمد من الاتحاد الدولي للسلامة الحيوية والأمن الحيوي في كندا وذلك لتفانيها في الحفاظ على أعلى معايير السلامة الحيوية.</p>
                                                                <br />
                                                                <p>* كمدرب معتمد برعاية منظمة الصحة العالمية (WHO) لتنقل خبرتها ومعرفتها للمحترفين الذين يسعون لتعزيز فهمهم لممارسات السلامة الحيوية.</p>
                                                                <br />
                                                                <p>* تلعب دورًا حيويًا كمستشار في مراكز مكافحة الأمراض والوقاية منها في أفريقيا وتساهم في تقديم تدابير مكافحة الأمراض وتعزيز التعاون بين الخبراء في هذا المجال.</p>
                                                                <br />
                                                                <p>* بفضل خبرتها الواسعة، تعمل كمشرفة على العديد من أطروحات الماجستير والدكتوراه في كليات الطب والجامعات في مصر، وتعتني بتنمية جيل المسؤولين الجدد في مجال السلامة الحيوية.</p>
                                                                <br />
                                                                <p>* شاركت بشكل نشط في المؤتمر العلمي الدولي للأمن الكيميائي والبيولوجي، الذي عقد تحت إشراف الجمعية الأمريكية للسلامة البيولوجية (ABSA) في عام 2017، مما يبرز التزامها بتقدم أبحاث السلامة الحيوية.</p>
                                                                <br />
                                                                <p>* كان لها تأثير كبير في مؤتمر الصحة العامة CPHIA في زامبيا في عام 2023، حيث قدمت خبرتها ورؤيتها حول ممارسات السلامة الحيوية.</p>
                                                                <br />
                                                                <p>* منذ عام 2020، شاركت بشكل بارز في تقديم محاضرات في المؤتمرات التي تنظمها الجمعية المصرية لمكافحة العدوى، مساهمة في نشر أفضل الممارسات في مجال مكافحة العدوى.</p>
                                                                <br />
                                                                <p>* بالتعاون مع منظمة الصحة العالمية (WHO)، قامت بتنظيم مؤتمرات ومحاضرات وورش عمل في مصر والأردن وتونس والمغرب لتعزيز الوعي بالسلامة الحيوية وبناء القدرات.</p>
                                                                <br />
                                                                <p>* كانت متحدثة متميزة في المؤتمرات والمحاضرات وورش العمل التي عُقدت في الجامعات المصرية البارزة مثل جامعة عين شمس وجامعة الأزهر وجامعة الريادة، ملهمة لمحترفي السلامة الحيوية في المستقبل.</p>
                                                                <br />
                                                                <p>* كمبتكرة حقيقية فقد قدمت مفاهيم السلامة الحيوية على مستويات مختلفة بما في ذلك المحافظات والمديريات والإدارات الصحية والمستشفيات والوحدات الصحية، محدثة ثورة في تطبيق السلامة الحيوية في مصر.</p>
                                                            </>)
                                                            :
                                                            (<>
                                                                <p>* She was born in Sharqia in 1975 and currently serves as the Technical Manager of the Food Microbiology Department and the Bio-Risk Officer at the Central Public Health Laboratories. Additionally she is a BRM Advisor for the BRM Network.</p>
                                                                <br />
                                                                <p>* In recognition of her outstanding contributions to biosafety she was honored with the prestigious Worldwide Biosafety Hero Award by the International Federation of Biosafety Societies in 2019 making history as the first Egyptian Arab and African woman to receive this esteemed accolade.</p>
                                                                <br />
                                                                <p>* Since 2022 she has been an integral part of the selection committee for the Worldwide Biosafety Hero Award actively involved in identifying and honoring individuals who have made significant strides in biosafety.</p>
                                                                <br />
                                                                <p>* Holding the esteemed title of Certified Bio-Risk Manager from the International Federation of Biosafety and Biosecurity in Canada she exemplifies her dedication to upholding the highest standards of biosafety.</p>
                                                                <br />
                                                                <p>* As a certified trainer endorsed by the World Health Organization (WHO) she imparts her expertise and knowledge to professionals seeking to enhance their understanding of biosafety practices.</p>
                                                                <br />
                                                                <p>* Playing a pivotal role as a facilitator at the Africa Centers for Disease Control and Prevention she contributes to the advancement of disease control measures and fosters collaboration among experts in the field.</p>
                                                                <br />
                                                                <p>* With her extensive experience she serves as a supervisor for numerous masters and doctoral theses in medical colleges and universities across Egypt nurturing the next generation of biosafety leaders.</p>
                                                                <br />
                                                                <p>* She actively participated in the International Scientific Conference on Chemical and Biological Security held under the guidance of The American Biological Safety Association (ABSA) in 2017 showcasing her commitment to advancing biosafety research.</p>
                                                                <br />
                                                                <p>* She made a significant impact at the CPHIA Public Health Conference in Zambia in 2023 sharing her expertise and insights on biosafety practices.</p>
                                                                <br />
                                                                <p>* Since 2020 she has been actively involved in presenting at conferences organized by the Egyptian Society for Infection Control contributing to the dissemination of best practices in infection control.</p>
                                                                <br />
                                                                <p>* Collaborating with the World Health Organization (WHO) she has conducted conferences lectures and workshops in Egypt Jordan Tunisia and Morocco promoting biosafety awareness and capacity building.</p>
                                                                <br />
                                                                <p>* She has been a distinguished speaker at conferences lectures and workshops held at prominent Egyptian universities such as Ain Shams University Al-Azhar University and Al-Reyada University inspiring future biosafety professionals.</p>
                                                                <br />
                                                                <p>* A true innovator she introduced the concepts of biosafety at various levels including governorates directorates health departments hospitals and health units revolutionizing biosafety practices in Egypt.</p>
                                                            </>)
                                                        }
                                                    </span><br />
                                                </p>
                                            </div>

                                        </div>
                                        <div className="cards">
                                            <img className='image' src={`${process.env.REACT_APP_PROJECTS_API}assest/imgs/${website.img1}`} alt="" />

                                            <img className='image' src={`${process.env.REACT_APP_PROJECTS_API}assest/imgs/${website.img2}`} alt="" />

                                            <img className='image' src={`${process.env.REACT_APP_PROJECTS_API}assest/imgs/${website.img3}`} alt="" />
                                        </div>

                                    </>
                                )
                            })}
                        </div>
                    </div>

                </div>
            </section>

            <Contact />
            <Footer />
        </>
    )
}

export default Achievements_Page
