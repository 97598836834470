
import All from "./components/All";
// import All_certificates from "./components/All_certificates";
import Contact from "./components/Contact";

import { BrowserRouter as Router, Route, Switch, Routes, BrowserRouter } from 'react-router-dom';
import Service from "./components/Service";
import Blog from "./components/Blog";
import All_Blogs from "./components/All_Blogs";
import All_certificates from "./components/All_certificates";
import Project from "./components/Project";
import Page_About from "./components/Page_about";
import Contact_Page from "./components/Contact_page";
import Achievements_Page from "./components/Achievements_Page";
import Gallery_Page from "./components/Gallery_page";
import Courses_Page from "./components/Courses_Page";
// import Service from "./components/Service";


function App () {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<All />} />
          <Route path="/about_page" element={<Page_About />} />
          <Route path="/achievements_page" element={<Achievements_Page />} />
          <Route path="/agendas_page" element={<All_Blogs />} />
          <Route path="/gallery_page" element={<Gallery_Page />} />
          <Route path="/courses_page" element={<Courses_Page />} />
          <Route path="/projects" element={<All_certificates />} />
          <Route path="/courses_page/course/:id" element={<Service />} />
          <Route path="/agendas_page/agenda/:id" element={<Blog />} />
          <Route path="/gallery_page/gallery/:id" element={<Project />} />
          <Route path="/contact_page" element={<Contact_Page />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
